/** @format */

@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/vendor/_rfs.scss';
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/_forms.scss';

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  @extend .form-control;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  width: 100%;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  height: 25%;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
