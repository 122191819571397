/** @format */

.nav-pills .nav-item .nav-link.active {
  color: #fff;
  background-color: var(--dark);
}

.nav-pills .nav-item .nav-link {
  color: var(--dark);
  background-color: transparent;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--dark);
  border-radius: 0.25rem 0.25rem 0 0;
}
