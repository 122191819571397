/** @format */

.editable:hover {
  background: #ffffff !important;
  color: #000000 !important;
}

.editable:focus {
  background: #ffffff !important;
  color: #000000 !important;
}

.editable {
  background: var(--dark) !important;
  color: #ffffff !important;
}

.cursor-pointer {
  cursor: pointer;
}
